import React, { Fragment, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { JOB_ORDER_RSURL,INVENTORY_LIST_URL, userHasAccess } from '../../../api/userUrl';
import { userGetMethod, userDeleteMethod } from '../../../api/userAction';
import { AddButton, EditButton, ShowButton, DeleteButton, PerPageBox } from '../../common/GlobalButton';
import Pagination from "react-js-pagination";
// import { Button, Modal } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import Show from './Show';
// import './listData.css'


export default function ListData(props) {
    const [jobOrderData, setJobOrderData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [sortParam, setSortParam] = useState('');
    const [hasAccess, setHasAccess] = useState({});
    const [accLoad, setAccLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState();
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [ascDesc, setAscDesc] = useState(true);
    const [show, setShow] = useState(false);
    const [showId,setShowId] = useState(0);
    

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true)
    setShowId(id)
};

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    useEffect(() => {
        // ADD,EDIT,DELETE,SHOW ACCESS CHECK
        userGetMethod(`${userHasAccess}/${menuId}`)
            .then(response => {
                setHasAccess(response.data);
                setAccLoad(false);
            });
            // handlePageChange();
       
    },[]);

    useEffect(() => {
            perPageBoxChange();
    },[perPage])
    const handleSearchText = (e) => {
        setSearchText(e);
    }
    const searchHandler = (e) => {
        setIsLoading(true);
        userGetMethod(ascDesc === true ? `${INVENTORY_LIST_URL}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=` : `${INVENTORY_LIST_URL}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
        .then(response => {
            setCurrentPage(response.data.productList.current_page)
            // setPerPage(response.data.productList.per_page)
            setTotalData(response.data.productList.total)
            setJobOrderData(response.data.productList.data)
            setIsLoading(false);
        })
        .catch(error => console.log(error)); 
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          searchHandler();
        }
      };

    const deleteHandler = (itemId, deleteLink) => {
        userDeleteMethod(deleteLink, itemId)
            .then(response => {
                if (response.data.status == 1) {
                    setIsLoading(true);
                    let newData = jobOrderData.filter(data => data.id != itemId);
                    setJobOrderData(newData);
                    setIsLoading(false);
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => toast.error(error));
    }

    
// console.log(jobOrderData);
    const perPageBoxChange = (e) => {
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascDesc === true ? `${INVENTORY_LIST_URL}?perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${INVENTORY_LIST_URL}?perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                setCurrentPage(response.data.productList.current_page)
                // setPerPage(response.data.productList.per_page)
                setTotalData(response.data.productList.total)
                setJobOrderData(response.data.productList.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    }

    const handlePageChange = (pageNumber = 1) => {
        setIsLoading(true);
        // console.log(pageNumber)
        // TABLE DATA READY
        userGetMethod(ascDesc === true? `${INVENTORY_LIST_URL}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${INVENTORY_LIST_URL}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                setCurrentPage(response.data.productList.current_page)
                // setPerPage(response.data.productList.per_page)
                setTotalData(response.data.productList.total)
                setJobOrderData(response.data.productList.data)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }
    useEffect(()=>{
        let ascUrl = '';
        if (ascDesc === true) {
            ascUrl = `${INVENTORY_LIST_URL}?perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`;
            
        } else {
            ascUrl = `${INVENTORY_LIST_URL}?perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`;
            
        }
        
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascUrl)
            .then(response => {
                setCurrentPage(response.data.productList.current_page)
                // setPerPage(response.data.productList.per_page)
                setTotalData(response.data.productList.total)
                setJobOrderData(response.data.productList.data)
                // console.log(jobOrderData);
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    },[ascDesc])

    const sortHandler = (params) => {
        setSortParam(params)
        setAscDesc(prevAscDesc => !prevAscDesc);
        
    }

    return (
        
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5>Consumable Product List</h5>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="input-group text-box searchBox">
                                        <input
                                            type="text"
                                            className="form-control input-txt-bx col-md-4"
                                            placeholder="Type to Search..."
                                            onChange={(e) => handleSearchText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="input-group-append">
                                            <button 
                                                className="btn btn-primary btn-sm" 
                                                type="button" 
                                                onClick={searchHandler} 
                                            >Go
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6">
                                    <AddButton link="consumableList/add" menuId={menuId} />
                                    <PerPageBox pageBoxChange={perPageBoxChange} perPage={perPage} setPerPage={setPerPage}/>
                                </div>
                            </div>
                                
                            <div className="card-body datatable-react">
                                {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col" width="8%" > SL.</th> */}
                                                    <th scope="col" width="5%" style={{cursor:'pointer'}} ><i className="fa fa-sort"></i> SL.</th>
                                                    <th scope="col" width="10%" style={{cursor:'pointer'}} onClick={() => sortHandler(1)}> <i className="fa fa-sort"></i> Product Id</th>
                                                    <th scope="col" width="15%" style={{cursor:'pointer'}} onClick={() => sortHandler(2)}> <i className="fa fa-sort"></i> Product Name</th>                                                      
                                                    <th scope="col" width="15%" style={{cursor:'pointer'}} onClick={() => sortHandler(3)}> <i className="fa fa-sort"></i> Unit</th>
                                                    <th scope="col" width="10%" style={{cursor:'pointer'}} onClick={() => sortHandler(4)}> <i className="fa fa-sort"></i> Unit Price</th>
                                                    <th scope="col" width="15%" style={{cursor:'pointer'}} onClick={() => sortHandler(5)}> <i className="fa fa-sort"></i> Re-Order Quantity</th>
                                                    <th scope="col" width="10%" style={{cursor:'pointer'}} onClick={() => sortHandler(1)}> <i className="fa fa-sort"></i>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    jobOrderData.length > 0 ? 
                                                        <>
                                                            {jobOrderData.map((item, index) =>           
                                                                (
                                                                    <tr key={index}>
                                                                        <td scope="row">{ ((index+1) + (currentPage == 1 ? 0 : (currentPage*perPage - perPage))) }</td>
                                                                        <td>00{item.id}</td>
                                                                        <td>{item.product_name}</td>
                                                                        <td>{item.unit}</td>
                                                                        <td>{item.unit_price}</td>
                                                                        <td>{item.reorder_qty}</td>
                                                                        <td className="">
                                                                            {
                                                                                accLoad === false ? <>
                                                                                    {hasAccess.edit === true? <EditButton link={`add`} menuId={{menuId,product_id:item.id} }   /> : ''} 
                                                                                    {/* {hasAccess.show === true ? <ShowButton handleShow=''   menuId={ menuId }

                                                                                    /> : ''}  */}
                                                                                    {hasAccess.destroy === true ? <DeleteButton deleteLink={INVENTORY_LIST_URL} deleteHandler={ deleteHandler } menuId={ menuId } dataId={item.id} /> : ''} 
                                                                                </> : ''
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )                
                                                            )}
                                                        </> 
                                                    : <tr><td colSpan="9" className="text-center">No data found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination 
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={totalData}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText="First"
                                    lastPageText="Last"
                                />
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
            
        <div>
        <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton style={{maxWidth:'none', width:"100%"}}>
          <Modal.Title>Job Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* <Show showId = {showId}></Show> */}
            </Modal.Body>
        
      </Modal>
        </div>
        </Fragment>
    )
}