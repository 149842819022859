import React, { useState, useEffect } from "react";
import { Row, Col, Card, Collapse, Table } from "react-bootstrap";
import { FaPlus, FaSyncAlt,FaMinus } from "react-icons/fa";
import { userGetMethod } from "../../../api/userAction";
import DashboardModal from './dashboardModal';
import { GET_DUE_CLINENTS } from "../../../api/userUrl";
import Draggable from 'react-draggable';

const UserDashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [jobFlowData, setJobFlowData] = useState([]);
  const [cashFlowData, setCashFlowData] = useState([]);
  const [cliendDuesList, setCliendDuesList] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [cashFlowLoading, setCashFlowLoading] = useState(false);
  const [dueClientLoading, setDueClientLoading] = useState(false);
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [dashboardOpen, setDashboardOpen] = useState(false);
  const [cashFlowOpen, setCashFlowOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [duesClients, setDuesClients] = useState(null);
  const [employeesData, setEmployeesData] = useState([]);

  const fetchData = async () => {
    const response = await userGetMethod("api/user/getDasboardDetails?module_id=6");
    setDashboardData(response.data.dashboard);
    setJobFlowData(response.data.jobFlowInfo);
    setCashFlowData(response.data.cashFlow || []);
  };

  const loadData = async (cardType) => {
    if (cardType === "dashboard") {
      setDashboardLoading(true);
      setDashboardOpen(false);
      await fetchData();
      setDashboardLoading(false);
      setDashboardOpen(true);
    } else if (cardType === "cashFlow") {
      setCashFlowOpen(false);
      setCashFlowLoading(true);
      await fetchData();
      setCashFlowLoading(false);
      setCashFlowOpen(true);
    }
  };
  const handleDuesClientClick = () => {
    setDuesClients(cashFlowData.TotalDuesClient);
    setDueClientLoading(true);
    userGetMethod(`${GET_DUE_CLINENTS}`)
      .then(response => {
        setCliendDuesList(response.data.dueClientsEmployeeList);
        setDueClientLoading(false);
      })
    
    setShowModal(true); // Open the modal
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    loadData("dashboard");
    loadData("cashFlow");
  }, []);

  const toggleCashFlowCollapse = () => setCashFlowOpen(!cashFlowOpen);
  return (
    <>
    <div className="d-flex justify-content-center align-items-center">
        <h2  style={{marginTop: '30px',marginBottom:'30px'}}>Welcome to Dashboard</h2>
    </div>
      <div>
       
        <Row>
        <Col md={6} sm={12} lg={6}>
        <Draggable>
          <Card className="mb-4" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
          <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="text-center">
                <h3>Job Flow</h3>
              </div>
              <div>
              {dashboardOpen ? (
                  <FaMinus
                  onClick={() => setDashboardOpen(!dashboardOpen)}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  />
                ) : (
                  <FaPlus
                  onClick={() => setDashboardOpen(!dashboardOpen)}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  />
                )}
                <span onClick={() => loadData("dashboard")} style={{ cursor: "pointer" }}>
                  {dashboardLoading  ? (
                    <FaSyncAlt
                      className="spinning-icon"
                      style={{ animation: "spin 1s linear infinite" }}
                    />
                  ) : (
                    <FaSyncAlt />
                  )}
                </span>
              </div>
            </Card.Header>
            <Collapse in={dashboardOpen}>
              <div>
                <Card.Body>
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead className="text-center">
                      <tr>
                        <th></th>
                        <th>S.A(lac)</th>
                        <th>Qty</th>
                        <th>New</th>
                        <th>Remake</th>
                        <th>Redo</th>
                        <th>DC/RC</th>
                        <th>Target Qty</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                        <tr>
                          <td>Today</td>
                          <td>{(parseInt(jobFlowData.total_surface_area_todays===null ? 0 :jobFlowData.total_surface_area_todays).toFixed(2) / 100000).toFixed(2)}</td>
                          <td>{parseInt(jobFlowData.total_cylinder_qty_todays === null ? 0 :jobFlowData.total_cylinder_qty_todays)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_todays_new=== null ? 0 :jobFlowData.cylinder_qty_todays_new)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_todays_remake=== null ? 0 :jobFlowData.cylinder_qty_todays_remake)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_todays_redo=== null ? 0 :jobFlowData.cylinder_qty_todays_redo)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_todays_dc_rc=== null ? 0 :jobFlowData.cylinder_qty_todays_dc_rc)}</td> 
                          <td>0</td> 
                        </tr>
                        <tr>
                          <td>This Week</td>
                          <td>{(parseInt(jobFlowData.total_surface_area_week === null ? 0 :jobFlowData.total_surface_area_week).toFixed(2) / 100000).toFixed(2)}</td>
                          <td>{parseInt(jobFlowData.total_cylinder_qty_week=== null ? 0 :jobFlowData.total_cylinder_qty_week)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_week_new=== null ? 0 :jobFlowData.cylinder_qty_week_new)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_week_remake=== null ? 0 :jobFlowData.cylinder_qty_week_remake)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_week_redo=== null ? 0 :jobFlowData.cylinder_qty_week_redo)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_week_dc_rc=== null ? 0 :jobFlowData.cylinder_qty_week_dc_rc)}</td> 
                          <td>0</td> 
                        </tr>
                        <tr>
                          <td>This Month</td>
                          <td>{(parseInt(jobFlowData.total_surface_area_month=== null ? 0 :jobFlowData.total_surface_area_month).toFixed(2) / 100000).toFixed(2)}</td>
                          <td>{parseInt(jobFlowData.total_cylinder_qty_month=== null ? 0 :jobFlowData.total_cylinder_qty_month)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_month_new=== null ? 0 :jobFlowData.cylinder_qty_month_new)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_month_remake=== null ? 0 :jobFlowData.cylinder_qty_month_remake)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_month_redo=== null ? 0 :jobFlowData.cylinder_qty_month_redo)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_month_dc_rc=== null ? 0 :jobFlowData.cylinder_qty_month_redo)}</td> 
                          <td>0</td> 
                        </tr>
                        <tr>
                          <td>This Year</td>
                          <td>{(parseInt(jobFlowData.total_surface_area_year=== null ? 0 :jobFlowData.total_surface_area_year).toFixed(2) / 100000).toFixed(2)}</td>
                          <td>{parseInt(jobFlowData.total_cylinder_qty_year=== null ? 0 :jobFlowData.total_cylinder_qty_year)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_year_new=== null ? 0 :jobFlowData.cylinder_qty_year_new)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_year_remake=== null ? 0 :jobFlowData.cylinder_qty_year_remake)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_year_redo=== null ? 0 :jobFlowData.cylinder_qty_year_redo)}</td> 
                          <td>{parseInt(jobFlowData.cylinder_qty_year_dc_rc=== null ? 0 :jobFlowData.cylinder_qty_year_dc_rc)}</td> 
                          <td>{parseInt(jobFlowData.YarlyTarget).toFixed(2)}</td> 
                        </tr>
                    </tbody>
                  </Table>
                  </div>
                </Card.Body>
              </div>
            </Collapse>
          </Card> 
        </Draggable>
        </Col>

        { 
        Object.keys(cashFlowData).length > 0 &&
          <Col md={6} sm={12} lg={4}> 
          <Draggable>
          <Card className="mb-4" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
          <Card.Header className="d-flex justify-content-between align-items-center">
                <h3>{dashboardData[1]?.menu_name || "Cash Flow"}</h3>
                <div>
                  {cashFlowOpen ? (
                    <FaMinus onClick={toggleCashFlowCollapse} style={{ cursor: "pointer", marginRight: "10px" }} />
                  ) : (
                    <FaPlus onClick={toggleCashFlowCollapse} style={{ cursor: "pointer", marginRight: "10px" }} />
                  )}
                  <span onClick={() => loadData("cashFlow")} style={{ cursor: "pointer" }}>
                    {cashFlowLoading ? (
                      <FaSyncAlt className="spinning-icon" />
                    ) : (
                      <FaSyncAlt />
                    )}
                  </span>
                </div>
              </Card.Header>
            <Collapse in={cashFlowOpen}>
              <div>
                <Card.Body>
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <tbody className="text-center">
                        <tr>
                          <td>Total Receivable</td>
                          <td>{parseInt(cashFlowData.receivableInfo===null ? 0 :cashFlowData.receivableInfo).toFixed(2)}</td>
                          <td>TK</td> 
                           
                           
                        </tr>
                        <tr>
                          <td>Todays Sales</td>
                          <td>{parseInt(cashFlowData.TodaysSale === null ? 0 :cashFlowData.TodaysSale).toFixed(2)}</td>
                          <td>TK</td> 
                           
                           
                        </tr>
                        <tr>
                          <td>Todays Collection</td>
                          <td>{parseInt(cashFlowData.todays_collection=== null ? 0 :cashFlowData.todays_collection).toFixed(2)}</td>
                          <td>TK</td> 
                           
                           
                        </tr>
                        <tr>
                          <td onClick={handleDuesClientClick} style={{ cursor: "pointer", color: "blue" }}>Dues Clients</td>
                          <td>{parseInt(cashFlowData.TotalDuesClient=== null ? 0 :cashFlowData.TotalDuesClient)}</td>
                          <td>Clients</td> 
                        </tr>
                    </tbody>
                  </Table>
                  </div>
                </Card.Body>
              </div>
            </Collapse>
          </Card> 
          </Draggable>
          </Col>
          }

       
      </Row>
      <DashboardModal closeModal={closeModal} showModal={showModal} duesClients={duesClients} cliendDuesList={cliendDuesList} dueClientLoading={dueClientLoading} setEmployeesData={setEmployeesData} employeesData={employeesData} setEmployeeLoading={setEmployeeLoading} employeeLoading={employeeLoading}/>
      <style>
        {`
          .spinning-icon {
            animation: spin 1s linear infinite;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
             .table-responsive::-webkit-scrollbar {
              width: 6px; /* Adjust the width to make it thinner */
              height: 6px;
            }

            .table-responsive::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            .table-responsive::-webkit-scrollbar-thumb {
              background-color: #888;
              border-radius: 4px;
            }

            .table-responsive::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
            
            .table-responsive {
              overflow-x: auto; /* Enable horizontal scrolling for responsive tables */
            }
                `}
      </style>
      </div>
    </>
  );
};

export default UserDashboard;
