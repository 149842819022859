import React, { Fragment, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { GET_DESIGN_LAYOUT_JOBORDER, GLOBAL_COLOR, JOB_ORDER_DETAILS, SPORTED_COLOR, supplier_List_API, supplierInformationAPI, UPDATE_COLOR_URL, userHasAccess } from '../../../../../api/userUrl';
import { userGetMethod, userDeleteMethod, userPutMethod, userPostMethod } from '../../../../../api/userAction';
import { AddButton, EditButton, DeleteButton, SubmitButton, SubmitProposeButton } from '../../../common/GlobalButton';
import Pagination from "react-js-pagination";
import { Typeahead } from 'react-bootstrap-typeahead';
import useForm from 'react-hook-form';

export default function ListData(props) {
    const { handleSubmit, register, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState({});
    const [accLoad, setAccLoad] = useState(true);
    const [jobNoFilter,setJobNoFilter] = useState('');
    const [typeheadOptions, setTypeheadOptions] = useState({ job_orders: [],});
    const [selectedJobOrder, setSelectedJobOrder] = useState([]);
    const [typeColorOptions, setTypeColorOptions] = useState([]);
    const [disableColorOptions, setDisableColorOptions] = useState([]);
    const [multipleDropdownData, setMultipleDropdownData] = useState([]);
    const [selectedColor,setSelectedColor] = useState([])
    const [selectedValue,setSelectedValue] = useState([])
    const [spotedColor, setSpotedColor] = useState(false);
    const [colorLength,setColorLength] = useState(0);

    
    const currentPath = window.location.pathname;

    // Split the path by '/' and get the last segment
    const segments = currentPath.split('/');
    let job_id = segments[segments.length - 1];

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    
    useEffect(() => {
        setIsLoading(true);
        // ADD,EDIT,DELETE,SHOW ACCESS CHECK
        userGetMethod(`${userHasAccess}/${menuId}`)
            .then(response => {
                setHasAccess(response.data);
                setAccLoad(false);
            });
            getColor();
            setIsLoading(false);
        // TABLE DATA READY
        if (job_id !== "layoutColor") {
            userGetMethod(`${JOB_ORDER_DETAILS}?jobOrderId=${job_id}`)
                    .then(response => {
                        setJobNoFilter(response.data.jobOrderDetails.job_no);
                
                    if (response?.data) {
                    if (response.data.colors.length > 0) {
                    let colorOptions = [];
                    response.data.colors.map((item, index) => {
                    let colorObj = {};
                        colorObj.er_color_serial = index;
                        colorObj.name = item?.color_name;
                        colorObj.id = item?.id;
                        colorOptions.push(colorObj);
                    })
                    setColorLength(colorOptions.length);
                    setTypeColorOptions(colorOptions);
                    setDisableColorOptions(colorOptions);
                }
            }
        })
        }
        setIsLoading(false);
    },[]);

    const handleTypeaheadInputChange = (text)=>{
        setJobNoFilter(text);
    }
    // let job_id = null;
    useEffect(()=>{
        setIsLoading(true);
            if (jobNoFilter.length > 3) {
                
                userGetMethod(`${GET_DESIGN_LAYOUT_JOBORDER}?searchText=${jobNoFilter}`)
                .then(response => {
                    console.log(response.data)
                    let jobOrderOptions = [];
                    if (response.data.jobOrders && response.data.jobOrders.length > 0) {
                    response.data.jobOrders.map(job => {
    
                        
                            let jobOrderObj = {};
                            jobOrderObj.id =job.id;
                            jobOrderObj.job_no =job.job_no;
                            jobOrderObj.name = job.job_name;

                            if (job.job_no === jobNoFilter) {
                                setSelectedValue([jobOrderObj])
                            }
                            jobOrderOptions.push(jobOrderObj);
                        }
                    )
                }
    
                setTypeheadOptions({ ...typeheadOptions, 
                    ['job_orders']: jobOrderOptions
                   });
                })
                // console.log(jobNoFilter)
            }
            setIsLoading(false);
        },[jobNoFilter])

    const getColor=()=>{
        
        userGetMethod(`${GLOBAL_COLOR}`)
          .then(response => {
              let additionalColorOptions = [];
                if (response.data.colors && response.data.colors.length > 0) {
                  response.data.colors.map(color => {
                    let colorObj = {};
                    colorObj.id = color.id;
                    colorObj.name = color.color_name;
                    additionalColorOptions.push(colorObj);
                  })
                }
        
                setMultipleDropdownData(additionalColorOptions)
              })
    }

    useEffect(()=>{
        if (spotedColor=== true) {
            userGetMethod(`${SPORTED_COLOR}`)
              .then(response => {
              let additionalColorOptions = [];
                if (response.data.colors && response.data.colors.length > 0) {
                  response.data.colors.map(color => {
                    let colorObj = {};
                    colorObj.id = color.id;
                    colorObj.name = color.color_name;
                    additionalColorOptions.push(colorObj);
                  })
                }
        
                setSelectedColor(additionalColorOptions)
              })
            }
    },[spotedColor])

    const multipleDropDownChange = (event) => {
            setTypeColorOptions(
               event
            )
        
    }

const dropDownChange = (event, stateName) => {
                if (stateName == 'job_id' && event[0]?.name) {
                    setSelectedJobOrder(event);
                    job_id = event[0].id;
                }
                const selectedValue = event[0].id;
                if (stateName == 'job_id' && selectedValue) {
                    userGetMethod(`${JOB_ORDER_DETAILS}?jobOrderId=${selectedValue}`)
                    .then(response => {
                
                    if (response?.data) {
                    if (response.data.colors.length > 0) {
                    let colorOptions = [];
                    response.data.colors.map((item, index) => {
                    let colorObj = {};
                        colorObj.er_color_serial = index;
                        colorObj.name = item?.color_name;
                        colorObj.id = item?.id;
                        colorOptions.push(colorObj);
                    })
                    setColorLength(colorOptions.length);
                    setTypeColorOptions(colorOptions);
                    setDisableColorOptions(colorOptions);
                }
            }
        })
    }
                
}
const submitHandler = (data, e) => {
    e.preventDefault();
    data.job_order_pk_id= job_id;;
    const previousColor = disableColorOptions.map((item) => item.id);
    const proposeColor = typeColorOptions.map((item) => item.id);
    data.propose_color = proposeColor;
    data.previous_color = previousColor;

    if (colorLength === typeColorOptions.length) {
        userPostMethod(`${UPDATE_COLOR_URL}`, data)
                    .then(response => {
                        console.log("response data", response);
                        if (response.data.status == 1) {
                            toast.success(response.data.message)
                        } else {
                            toast.error(response.data.message)
                        }
                    })
                .catch(error => toast.error(error))
    }else{
        toast.error("Color quantity not same")
    }
  }

    return (
        
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Update Layout Color</h5>
                                    </div>
                                    
                                </div>
                            </div>
                                
                            <div className="card-body datatable-react">
                                {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <form 
                                    onSubmit={handleSubmit(submitHandler)}
                                     className="theme-form row">
                                        <div className="col-md-8">
                                        <fieldset className="border p-2" >
                                            <legend className="w-auto text-left">Job and Color Information</legend>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label required">Design Layout</label>
                                                <div className="col-md-9">
                                                    <Typeahead
                                                    
                                                        id="job_id"
                                                        name="job_id"
                                                        labelKey={option => `${option.job_no}${option.name}`}
                                                        options={typeheadOptions['job_orders'] || []}
                                                        placeholder="Enter 4 or more characters to search..."
                                                        onChange={(e) => dropDownChange(e, 'job_id')}
                                                        selected={selectedValue||selectedJobOrder}
                                                        onInputChange={(text) => handleTypeaheadInputChange(text)}
                                                        ref={register({
                                                        required: 'On text Field Required'})}
                                                        maxHeight={300}
                                                        
                                                        />
                                                </div>

                                                {/* <label className="col-md-3 col-form-label label-form required ">Color</label>
                                                <div className="col-md-9 mt-2">
                                                    <Typeahead
                                                        id="color"
                                                        multiple
                                                        name="color"
                                                        labelKey={option => `${option.name}`}
                                                        options={typeColorOptions || []}
                                                        placeholder="Select Color..."
                                                        onChange={(e) => dropDownChange(e, 'color')}
                                                        selected={typeColorOptions}
                                                        ref={register({
                                                        required: 'On text Field Required'})}
                                                    />
                                                </div> */}
                                                
                                            </div>
                                            <div className="form-group row mt-4">
                                                <label className="col-sm-3 col-md-3 col-form-label required" htmlFor="color_id">Previous Color</label>
                                                <div className="col-sm-6 col-md-6">
                                                <Typeahead
                                                    id="color_id" name="color_id"
                                                    labelKey={option => `${option.name}`}
                                                    multiple required
                                                    options={(spotedColor === false ? multipleDropdownData:selectedColor)|| []}
                                                    placeholder="Select Color..."
                                                    // onChange={(e) => multipleDropDownChange(e)}
                                                    selected={disableColorOptions}
                                                    disabled
                                                    ref={register({required: 'Color Field Required' })}
                                                    />
                                                    {errors.color_id && <p className='text-danger'>{errors.color_id.message}</p>}
                                                </div>
                                                
                                            </div>
                                            <div className="form-group row mt-4">
                                                <label className="col-sm-3 col-md-3 col-form-label required" htmlFor="color_id">Propose Color</label>
                                                <div className="col-sm-6 col-md-6">
                                                <Typeahead
                                                    id="color_id" name="color_id"
                                                    labelKey={option => `${option.name}`}
                                                    multiple required
                                                    options={(spotedColor === false ? multipleDropdownData:selectedColor)|| []}
                                                    placeholder="Select Color..."
                                                    onChange={(e) => multipleDropDownChange(e)}
                                                    selected={typeColorOptions}
                                                    ref={register({required: 'Color Field Required' })}
                                                    />
                                                    {errors.color_id && <p className='text-danger'>{errors.color_id.message}</p>}
                                                </div>
                                                <label className="col-sm-2 col-md-2 col-form-label" htmlFor="spot_color">
                                                    Spot color
                                                </label>
                                                <div className="col-sm-1 col-md-1 mt-2">
                                                    <input name="spot_color"
                                                        onChange={(e) => setSpotedColor(e.target.checked)}
                                                        type="checkbox"/>
                                                    </div>
                                            </div>
                                            <div className='text-center' style={{color:"gray"}}>
                                                <p>NB: Color quantity will be same as previous quantity {colorLength}</p>
                                            </div>
                                        </fieldset>
                                        <SubmitProposeButton link="designLayout/index" menuId={menuId}/>
                                    </div>
                                    </form>
                                   
                                )}
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}