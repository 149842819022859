import React, { Fragment, useEffect, useState } from 'react';
import { supplier_List_API, supplierInformationAPI } from '../../../../api/userUrl';
import { userGetMethod, userPostMethod, userPutMethod } from '../../../../api/userAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useForm from "react-hook-form";
import { SubmitButton } from '../../../common/GlobalButton';

const Add = (props) => {
    const { handleSubmit, register, errors } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [supplierValue, setSupplierValue] = useState({});

    
    var menuId = 0;
    let supplier_id = 0;
    if (props.location.state === undefined) {
        var menuId = 0;
    }else{
        menuId = props.location.state.params.menuId;
        supplier_id = props.location.state.params.menuId.supplier_id;
    }

     useEffect(()=>{
            setIsLoading(true);
            if (supplier_id !== 0 && supplier_id !== undefined) {
                userGetMethod(`${supplier_List_API}/${supplier_id}/edit`)
                   .then(response => {
                    // console.log(response.data);
    
                    setSupplierValue(response.data.supplierList);
                    setIsLoading(false);
                   })
                   .catch(error => console.log(error))
            }else{
                setIsLoading(false);
            }
                   
        }, [supplier_id]);

    const inputHander = (event) => {
            const { name, value } = event.target;
    
            setSupplierValue((prevState) => ({
                ...prevState, 
                [name]: value,
            }));
            
        }

    const submitHandler = (data, e) => {
        console.log('data', data);

        if (supplier_id !== 0 && supplier_id !== undefined) {
             userPutMethod(`${supplier_List_API}/${supplier_id}`, data)
                        .then(response => {
                            console.log("response.data", response.data);
                            if (response.data.status == 1) {
                                toast.success(response.data.message)
                                clearForm();
                                e.target.reset();
                            } else {
                                toast.error(response.data.message)
                            }
                        })
                    .catch(error => toast.error(error))
        }else{
            userPostMethod(supplier_List_API, data)
                .then(response => {
                    if (response.data.status == 1) {
                        clearForm();
                        e.target.reset();
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data.message)
                    }
                })
            .catch(error => toast.error(error))
        }
    }

    const clearForm = ()=>{
        setSupplierValue({});
    }
    
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Add Inventory Supplier Information</h5>
                            </div>
                            <div className="card-body">
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                            <form onSubmit={handleSubmit(submitHandler)} className="theme-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label required" htmlFor="supplier_name">Supplier Name</label>
                                        <div className="col-sm-8">
                                            <input 
                                                className="form-control"
                                                id="supplier_name" 
                                                name="supplier_name" 
                                                type="text" 
                                                placeholder="Supplier Name"
                                                value={supplierValue?.supplier_name || ''}
                                                onChange={inputHander}
                                                ref={register({
                                                    required: 'Name Field Required'
                                                })}
                                            />
                                            {errors.name && <p className='text-danger'>{errors.name.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label required" htmlFor="email">Email</label>
                                        <div className="col-sm-8">
                                            <input 
                                                className="form-control"
                                                id="email" 
                                                name="email" 
                                                type="text" 
                                                placeholder="Email Address"
                                                onChange={inputHander}
                                                value={supplierValue?.email || ''}
                                                ref={register({
                                                    required: 'Email Field Required'
                                                })}
                                            />
                                            {errors.email && <p className='text-danger'>{errors.email.message}</p>}
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label" htmlFor="phone">Telephone</label>
                                        <div className="col-sm-8">
                                            <input 
                                                className="form-control"
                                                id="phone" 
                                                name="phone" 
                                                type="text" 
                                                placeholder="Telephone No."
                                                onChange={inputHander}
                                                value={supplierValue?.phone || ''}
                                                ref={register({})}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label required" htmlFor="contact_number">Mobile</label>
                                        <div className="col-sm-8">
                                            <input 
                                                className="form-control"
                                                id="contact_number" 
                                                name="contact_number" 
                                                type="text" 
                                                placeholder="Mobile No."
                                                onChange={inputHander}
                                                value={supplierValue?.contact_number || ''}
                                                ref={register({
                                                    required: 'Mobile Field Required'
                                                })}
                                            />
                                            {errors.mobile && <p className='text-danger'>{errors.mobile.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label" htmlFor="address">Address</label>
                                        <div className="col-sm-8">
                                            <textarea 
                                                className="form-control"
                                                id="address" 
                                                name="address" 
                                                type="text" 
                                                value={supplierValue?.address || ''}
                                                placeholder="Address"
                                                onChange={inputHander}
                                                ref={register({})}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label" htmlFor="contact_person">Contact Person</label>
                                        <div className="col-sm-8">
                                            <textarea 
                                                className="form-control"
                                                id="contact_person" 
                                                name="contact_person" 
                                                type="text" 
                                                value={supplierValue?.contact_person || ''}
                                                placeholder="Contact Person"
                                                onChange={inputHander}
                                                ref={register({})}
                                            />
                                        </div>
                                    </div>
                                </div>

                                
                                {/* <div className="col-md-6">
                                    <fieldset className="border" >
                                        <legend className="w-auto text-left">Billing Configuration</legend>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label required" htmlFor="config_status">Config Status</label>
                                            <div className="col-sm-8">
                                                <select className="form-control" id="config_status" name="config_status" onChange={e=>setConfigStatus(e.target.value)}
                                                    ref={register({
                                                        required: 'Config Status Field Required'
                                                    })} >
                                                    <option value=""> Select One </option>
                                                    <option value="1"> Square Inch </option>
                                                    <option value="2"> Square CM </option>
                                                </select>
                                                {errors.config_status && <p className='text-danger'>{errors.config_status.message}</p>}
                                            </div>
                                        </div>
                                        {configStatus == 1 ? (
                                            <div className="form-group row">
                                                <label className="col-md-4 col-form-label required" htmlFor="sqr_inch_amount">Square Inch Amount</label>
                                                <div className="col-md-8">
                                                    <input 
                                                        className="form-control"
                                                        id="sqr_inch_amount" 
                                                        name="sqr_inch_amount" 
                                                        type="text" 
                                                        ref={register({
                                                            required: 'Square Inch Amount Notify Field Required'
                                                        })}
                                                    />
                                                    {errors.sqr_inch_amount && <p className='text-danger'>{errors.sqr_inch_amount.message}</p>}
                                                </div>
                                            </div>
                                        ) 
                                        :
                                        configStatus == 2 ? (
                                            <div className="form-group row">
                                                <label className="col-md-4 col-form-label required" htmlFor="sqr_cm_amount">Square CM Amount</label>
                                                <div className="col-md-8">
                                                    <input 
                                                        className="form-control"
                                                        id="sqr_cm_amount" 
                                                        name="sqr_cm_amount" 
                                                        type="text" 
                                                        ref={register({
                                                            required: 'Square CM Amount Notify Field Required'
                                                        })}
                                                    />
                                                    {errors.sqr_cm_amount && <p className='text-danger'>{errors.sqr_cm_amount.message}</p>}
                                                </div>
                                            </div>
                                            )
                                            :
                                            null
                                        }
                                    </fieldset>
                                
                                </div> */}


                            </div>
                            <SubmitButton link="consumableSupplier/index" menuId={ menuId } />
                        </form>
                        }
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        
        </Fragment>
    );
};

export default Add;