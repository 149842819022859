import React, { Fragment, useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useForm from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
// import { PanelRefreshIcons, SubmitButton } from '../../../common/GlobalButton';
import { userGetMethod, userPostMethod } from "../../../../api/userAction";
import {
  CLIENT_STOCK_RSURL,
  GET_CLIENT_STOCK_JOB_RSURL,
  JOB_ORDER_DETAILS,
  JOB_ORDER_RSURL,
  ShiftFor,
  STOCK_ISSUE_URL,
  UNKNOWN_STOCK_RSURL,
} from "../../../../api/userUrl";
import SweetAlert from "sweetalert2";
// import { placeHolderText } from '../../common/GlobalComponent';
// import { trStyleNormal } from '../jobAgreement/Create';
import moment from "moment";
import { SubmitButton } from "../../common/GlobalButton";

const Add = (props) => {
  const { handleSubmit, register, errors, reset } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownData, setDropdownData] = useState({});
  const [typeheadOptions, setTypeheadOptions] = useState({});
  const [pnoValue, setPnoValue] = useState({});
  const [clientStockDetails, setClientStockDetails] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [units, setUnits] = useState();

  let [jobOrderData, setJobOrderData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      issue_to: "",
      ref_no: "",
      unit_price: "",
      unit: "",
      remarks: "",
      product_id: "",
      product_name:"",
      department: "",
      quantity: "",
      invoice_date: moment().format("YYYY-MM-DD"),
      receive_date: moment().format("YYYY-MM-DD"),
    }
  );
  // let job_order_id = props.location.state.params.job_order_id ? props.location.state.params.job_order_id : null;
  useEffect(() => {
    setIsLoading(true);
    // pageRefreshHandler(job_order_id);
    userGetMethod(`${ShiftFor}`)
      .then((response) => {
        setOptions(response.data.shiftNames);
        // setTotalEmployees(response.data.allEmployes);
      })
      .catch((error) => {
        console.log(error);
      });
    userGetMethod(`${STOCK_ISSUE_URL}/create`)
      .then((response) => {
        setUnits(response.data.units);
        let purchase_order = [];
        if (
          response.data.Purchase_orders &&
          response.data.Purchase_orders.length > 0
        ) {
          response.data.Purchase_orders.map((subClass) => {
            let purchaseObj = {};
            purchaseObj.id = subClass.id;
            purchaseObj.name = `[${subClass.id}]-` + subClass.ref_no;
            purchaseObj.ref_date = subClass.ref_date;
            purchase_order.push(purchaseObj);
          });
        }
        let pnos = [];
        if (response.data.pnos && response.data.pnos.length > 0) {
          response.data.pnos.map((subClass) => {
            let pnosObj = {};
            pnosObj.id = subClass.id;
            pnosObj.name = `[${subClass.id}]-` + subClass.consumeable_user;
            pnos.push(pnosObj);
          });
        }
        let products = [];
        if (response.data.products && response.data.products.length > 0) {
          response.data.products.map((subClass) => {
            let productsObj = {};
            productsObj.id = subClass.id;
            productsObj.name = `[${subClass.id}]-` + subClass.product_name;
            productsObj.unit = subClass.unit;
            products.push(productsObj);
          });
        }

        setTypeheadOptions((prevstate) => ({
          ...prevstate,
          ["products"]: products,
          ["pnos"]: pnos,
          ["purchase_orders"]: purchase_order,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  }, []);

  // FOR Typeahead DATA INPUT
  const dropDownChange = (event, stateName) => {
    if (event.length > 0) {
      const selectedValue = event[0].id;
      const selectedValueName = event[0].name;
      const selectedUnit = event[0]?.unit;
      
      if (stateName==="pnos") {
        setPnoValue({
            pno_to : selectedValue,
            pno_to_name : selectedValueName
        })
      }
      if(stateName === "products"){
        setJobOrderData({
            unit: selectedUnit,
            product_id:selectedValue,
            product_name:selectedValueName
            });
      }
    }
  };
  // FOR CLIENT STOCKS DATA INPUT
  const clientStocksInputHander = (event) => {
    setJobOrderData({ [event.target.name]: event.target.value });
  };

  // FOR CLIENT STOCKS ARRAY READY
  const addOrderDetailsHandler = (event) => {
    let {
      ref_no,
      unit_price,
      product_name,
      unit,
      remarks,
      product_id,
      quantity,
      invoice_date,
    } = jobOrderData; 
    

    if (quantity) {
    //   let clientStockDetails_arr = [];
    //   let clientStockDetails_obj = {};
    //   clientStockDetails_obj.product_id = product_id;
    //   clientStockDetails_obj.invoice_date = invoice_date;
    //   clientStockDetails_obj.quantity = quantity;
    //   clientStockDetails_obj.unit = unit;
    //   clientStockDetails_obj.unit_price = unit_price;
    //   clientStockDetails_obj.ref_no = ref_no;
    //   clientStockDetails_obj.remarks = remarks;

    //   clientStockDetails_arr.push(clientStockDetails_obj);
    //   setClientStockDetails([...clientStockDetails, ...clientStockDetails_arr]);
    
        setClientStockDetails((prevStockDetails) => {
          // Check if the product_id already exists in the state
          const existingProduct = prevStockDetails.find(
            (product) => product.product_id === product_id
          );
    
          if (existingProduct) {
            // Update the existing product's quantity and unit_price
            return prevStockDetails.map((product) =>
              product.product_id === product_id
                ? {
                    ...product,
                    quantity: Number(product.quantity) + Number(quantity), // Add new quantity to existing
                    unit_price: Number(product.unit_price) + Number(unit_price), // Update to the latest unit_price
                    unit: Number(unit),
                    remarks: remarks,
                    ref_no: Number(ref_no),
                    product_name:product_name,
                    invoice_date: invoice_date,
                    issue_to:pnoValue.pno_to_name
                  }
                : product
            );
          } else {
            // Add a new product to the state
            let clientStockDetails_obj = {
              product_id,
              invoice_date,
              quantity,
              unit,
              unit_price,
              ref_no,
              remarks,
              product_name,
              issue_to:pnoValue.pno_to_name
            };
    
            return [...prevStockDetails, clientStockDetails_obj];
          }
        });

      setJobOrderData({
        product_name:"",
        issue_to: "",
        ref_no: "",
        unit_price: "",
        unit: "",
        remarks: "",
        product_id: "",
        department: "",
        quantity: "",
        receive_date: moment().format("YYYY-MM-DD"),
      });
    } else {
      if(!jobOrderData.product_id){
      SweetAlert.fire({ title: "Warning", text: "Your Job Order Cyl quantity is 0, Please Select product first", icon: "warning" });
      }else if(!invoice_date){
          SweetAlert.fire({ title: "Warning", text: "Please enter receieve date",icon: "warning" });
      }else{
          SweetAlert.fire({ title: "Warning", text: "Please all input",icon: "warning" });
      }
    }
  };

  // FOR REMOVE CLIENT STOCKS SINGLE DATA FROM CLIENT STOCKS ARRAY
  const removeClientStockHandler = (product_id) => {
    let availableClientStock = clientStockDetails.filter(
      (item) => item.product_id != product_id
    );
    setClientStockDetails([...availableClientStock]);
  };
  // FINALLY SUBMIT FOR SAVE TO SERVER
  const submitHandler = (data, e) => {
    data.stockIssueArr = clientStockDetails;
    data.pno_to = pnoValue.pno_to;
    data.issue_date = jobOrderData.invoice_date;
    console.log(data)

    if (jobOrderData.pno_to ) {
        userPostMethod(STOCK_ISSUE_URL, data)
            .then(response => {
                console.log(response);
                if (response.data.status == 1) {
                    toast.success(response.data.message)
                    clearFormField(e);
                    e.target.reset();
                } else {
                    toast.error(response.data.message)
                }
            })
            .catch(error => toast.error(error))
    } else {
        SweetAlert.fire({ title: "Warning", text: "Please place issue to!", icon: "warning" });
    }
  };
  const clearFormField = (event) => {
    setJobOrderData({
        issue_to: "",
        ref_no: "",
        unit_price: "",
        unit: "",
        remarks: "",
        product_id: "",
        product_name:"",
        department: "",
        quantity: "",
        invoice_date: moment().format("YYYY-MM-DD"),
        receive_date: moment().format("YYYY-MM-DD"),
    });
    setSelectedValue([]);
    setClientStockDetails([]);
    setPnoValue({});
    setUnits();
  };

  var menuId = 0;
  if (props.location.state === undefined) {
    var menuId = 0;
  } else {
    menuId = props.location.state.params.menuId;
  }
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6">
                    <h5>Stock Issue Add</h5>
                  </div>
                  <div className="col-md-6">
                    {/* <PanelRefreshIcons panelRefresh={pageRefreshHandler} /> */}
                  </div>
                </div>
              </div>
              <div className="card-body">
                {isLoading ? (
                  <img
                    src={process.env.PUBLIC_URL + "/preloader.gif"}
                    alt="Data Loading"
                  />
                ) : (
                  <form
                    onSubmit={handleSubmit(submitHandler)}
                    className="theme-form"
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group row">
                          <label
                            className="col-sm-4 col-form-label required"
                            htmlFor="invoice_date"
                          >
                            Invoice date
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              id="invoice_date"
                              name="invoice_date"
                              value={
                                jobOrderData.invoice_date
                                  ? jobOrderData.invoice_date
                                  : moment().format("YYYY-MM-DD")
                              }
                              onChange={clientStocksInputHander}
                              required
                              type="date"
                              ref={register({
                                required: "Product No Field Required",
                              })}
                            />
                            {errors && (
                              <p className="text-danger">{errors.message}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group row">
                          <label
                            className="col-sm-4 col-form-label required"
                            htmlFor="issue_to"
                          >
                            Issue To
                          </label>
                          <div className="col-sm-8">
                            <Typeahead
                              id="pnos"
                              name="pnos"
                              labelKey={(option) => `${option.name}`}
                              options={typeheadOptions["pnos"] || []}
                              placeholder="Select product..."
                              onChange={(e) => dropDownChange(e, "pnos")}
                              inputProps={{}}
                              // selected={typeAheadValue["pnos"]}
                              // ref={register({
                              //     required: 'Sub Class Field Required'
                              // })}
                              {...register("pnos")}
                            />
                            {errors && (
                              <p className="text-danger">{errors.message}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-t-10 m-l-10">
                      <fieldset className="border" style={{ width: "98%" }}>
                        <legend className="w-auto text-left">
                          Issue Details
                        </legend>
                        <div className="col-md-12">
                          <div className="form-row">
                            <div className="col-md-2 mb-3">
                              <label for="product_id" className="required">
                                Product ID
                              </label>
                              <Typeahead
                                id="products"
                                name="products"
                                labelKey={(option) => `${option.name}`}
                                options={typeheadOptions["products"] || []}
                                placeholder="Select product..."
                                onChange={(e) =>
                                  dropDownChange(e, "products")
                                }
                                inputProps={{}}
                                // selected={typeAheadValue["products"]}
                                // ref={register({
                                //     required: 'Sub Class Field Required'
                                // })}
                                {...register("products")}
                              />
                            </div>
                            <div className="col-md-2 mb-3">
                              <label for="remarks" className="required">
                                Remarks
                              </label>
                              <input
                                className="form-control"
                                id="remarks"
                                name="remarks"
                                type="text"
                                placeholder="remarks.."
                                onChange={clientStocksInputHander}
                                value={jobOrderData.remarks || ""}
                              />
                            </div>

                            <div className="col-md-2 mb-3">
                              <label for="unit" className="required">
                                Unit
                              </label>
                              <select
                                className="form-control"
                                id="unit"
                                // name="unit"
                                onChange={clientStocksInputHander}
                                ref={register({
                                  
                                })}
                                disabled={jobOrderData?.unit ? true : false}
                                value={jobOrderData?.unit || ""}
                              >
                                <option value="">Select a unit</option>
                                {units &&
                                Array.isArray(units) &&
                                units.length > 0 ? (
                                  units.map((option, index) => (
                                    <option key={index} value={option.id}>
                                      {option.unit_name}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled>
                                    No units available
                                  </option>
                                )}
                              </select>
                            </div>
                            <div className="col-md-2 mb-3">
                              <label for="unit_price" className="required">
                                Unit Price
                              </label>
                              <input
                                className="form-control"
                                id="unit_price"
                                name="unit_price"
                                type="text"
                                placeholder="unit price"
                                onChange={clientStocksInputHander}
                                value={jobOrderData.unit_price}
                              />
                            </div>
                            <div className="col-md-1 mb-3">
                              <label for="quantity" className="required">
                                Quantity
                              </label>
                              <input
                                className="form-control"
                                id="quantity"
                                name="quantity"
                                type="number"
                                placeholder="quantity"
                                onChange={clientStocksInputHander}
                                value={jobOrderData.quantity}
                              />
                            </div>
                            <div className="col-md-2 mb-3">
                              <label for="ref_no" className="required">
                                Ref. No
                              </label>
                              <input
                                className="form-control"
                                id="ref_no"
                                name="ref_no"
                                type="number"
                                placeholder="ref no"
                                onChange={clientStocksInputHander}
                                value={jobOrderData.ref_no}
                              />
                            </div>

                            <input
                              className="form-control"
                              id="client_name"
                              name="client_name"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.client_name}
                            />
                            <input
                              className="form-control"
                              id="id"
                              name="id"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.id}
                            />
                            <input
                              className="form-control"
                              id="client_id"
                              name="client_id"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.client_id}
                            />
                            <input
                              className="form-control"
                              id="printer_id"
                              name="printer_id"
                              type="hidden"
                              onChange={clientStocksInputHander}
                              value={jobOrderData.printer_id}
                            />

                            <div className="col-md-1 mb-4 m-t-5">
                              <span
                                className="btn btn-primary btn-sm mr-1 m-t-20"
                                type="add"
                                onClick={addOrderDetailsHandler}
                              >
                                Add
                              </span>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div className="row m-t-10 m-l-10">
                      <fieldset className="border" style={{ width: "98%" }}>
                        <legend className="w-auto text-left">
                          Added Stock Issue
                        </legend>
                        <div className="col-md-12">
                          <table
                            className="table table-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th scope="col" width="10%">
                                  Product ID
                                </th>
                                <th scope="col" width="15%">
                                  Product Name
                                </th>
                                <th scope="col" width="10%">
                                  Quantity
                                </th>
                                <th scope="col" width="15%">
                                  Unit Price
                                </th>
                                <th scope="col" width="20%">
                                  Issue to
                                </th>
                                <th scope="col" width="15%">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {clientStockDetails.length > 0 ? (
                                <>
                                  {clientStockDetails.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.product_id}</td>
                                      <td>{item.product_name}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.unit_price}</td>
                                      <td>{item.issue_to}</td>
                                      
                                      <td align="center">
                                        <span
                                          onClick={() =>
                                            removeClientStockHandler(
                                              item.product_id
                                            )
                                          }
                                        >
                                          <i
                                            className="icon-close"
                                            style={{
                                              width: 25,
                                              fontSize: 16,
                                              padding: 0,
                                              color: "#e4566e",
                                              cursor: "pointer",
                                            }}
                                          ></i>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <tr>
                                  <td colSpan="8" className="text-center">
                                    No data Added
                                  </td>
                                </tr>
                              )}
                            </tbody>

                            <tfoot>
                              <tr>
                                <td colSpan="5" align="right">
                                  Total ={" "}
                                </td>
                                <td>0</td>
                                {/* <td align="right">Total :</td>
                                                                    <td>0</td> */}
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </fieldset>
                    </div>

                    <SubmitButton
                      link="consumableStockIssue/index"
                      menuId={menuId}
                      offset="4"
                    />
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Add;
