import React, { Fragment, useState, useEffect, useReducer } from 'react';
import useForm from "react-hook-form";
import { SubmitButton } from '../../common/GlobalButton';
import { userGetMethod, userPostMethod, userPutMethod } from '../../../api/userAction';
import { INVENTORY_LIST_URL } from '../../../api/userUrl';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trStyleNormal } from '../jobAgreement/Create';

const Add = (props) => {
    const { handleSubmit, register, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [resource, setResource] = useState(false);
    const [status, setStatus] = useState(true);
    const [typeHeadOptions, setTypeHeadOptions] = useState({});
    const [dropDownData, setDropdownData] = useState();
    const [file,setFile] = useState();
    const [jobNoValue,setJobNoValue] = useState({
        product_name:'',
        product_source:'',
        Product_type:'',
        unit:'',
        unit_price:'',
        reorder_qty:'',

    });
    const [productId ,setProductId] = useState('');

    
    var menuId = 0;
    let product_id = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    }else{
        menuId = props.location.state.params.menuId;
        product_id = props.location.state.params.menuId.product_id;
    }
    
    useEffect(()=>{
        setIsLoading(true);
        userGetMethod(`${INVENTORY_LIST_URL}/create`)
               .then(response => {
                // console.log(response.data);

                if (response.data && Array.isArray(response.data.units)) {
                    setDropdownData(response.data.units);
                } else {
                    console.error("Expected 'units' to be an array");
                }
                setIsLoading(false);

                // setDropdownData(response.data.units)
                // setIsLoading(false);
               })
               .catch(error => console.log(error))
        if (product_id !== 0) {
            userGetMethod(`${INVENTORY_LIST_URL}/${product_id}/edit`)
               .then(response => {
                // console.log(response.data);

                setJobNoValue(response.data.productList);
                setIsLoading(false);
               })
               .catch(error => console.log(error))
        }
               
    }, [product_id]);
    
    const clientStocksInputHander = (event) => {
        const { name, value } = event.target;

        setJobNoValue((prevState) => ({
            ...prevState, 
            [name]: value,
        }));
        
    }
    
  
    const submitHandler = (data, e) => {
        // data.job_order_id = dropDownData.job_order_id;
        // const formData = new FormData();
        if (product_id !== 0) {
            userPutMethod(`${INVENTORY_LIST_URL}/${product_id}`, data)
            .then(response => {
                console.log("response.data", response.data);
                if (response.data.status == 1) {
                    toast.success(response.data.message)
                    clearForm();
                    e.target.reset();
                } else {
                    toast.error(response.data.message)
                }
            })
        .catch(error => toast.error(error))
        }else{
        userPostMethod(INVENTORY_LIST_URL, data)
            .then(response => {
                console.log("response.data", response.data);
                if (response.data.status == 1) {
                    toast.success(response.data.message)
                    clearForm();
                    e.target.reset();
                } else {
                    toast.error(response.data.message)
                }
            })
        .catch(error => toast.error(error))
        }
    }

    const clearForm = () => {
        setJobNoValue({
            product_name:'',
            product_source:'',
            Product_type:'',
            unit:'',
            unit_price:'',
            reorder_qty:'',
    
        })
    }
    
    return (
        
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Consumable Registration</h5>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <PanelRefreshIcons panelRefresh={pageRefreshHandler} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                            {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                <form onSubmit={handleSubmit(submitHandler)} className="needs-validation theme-form">
                                    <div className="row">
                                        <div className="col-md-8" style={{marginTop: '20px'}}>
                                            {/* <div className="form-group row">
                                                <label className="col-sm-3 col-form-label required" htmlFor="job_no">Product ID</label>
                                                <div className="col-sm-9">
                                                    <Typeahead
                                                        id="job_order_id"
                                                        name="job_order_id"
                                                        labelKey={option => `${option.name}`}
                                                        options={typeHeadOptions['job_orders']}
                                                        placeholder="Select Job No..."
                                                        onChange={(e) => dropDownChange(e, 'job_order_id')}
                                                        selected={jobNoValue}
                                                        disabled={job_order_id != null ? 'disabled' : ''}
                                                        ref={register({
                                                            required: 'Job No Field Required'
                                                        })}
                                                    />
                                                    {errors.job_order_id && 'Job No. is required'}
                                                </div>
                                            </div> */}

                                            

                                            <div className="form-group row mt-2">
                                                <label className="col-sm-3 col-form-label" htmlFor="product_name">Product Name</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="product_name" value={jobNoValue?.product_name || ''} name="product_name" onChange={clientStocksInputHander} type="text" placeholder="product name" ref={register({})} />
                                                </div>
                                            </div>
                                            <div className="form-group row mt-2">
                                                <label className="col-sm-3 col-form-label" htmlFor="Product_type">Product Type</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="Product_type" name="Product_type" type="text" onChange={clientStocksInputHander} value={jobNoValue?.Product_type || ''} placeholder="Product_types" ref={register({})} />
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-5'>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label" htmlFor="unit">
                                                            Unit
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <select
                                                            className="form-control"
                                                            id="unit"
                                                            name="unit" onChange={clientStocksInputHander}
                                                            ref={register({
                                                                required: "select one option"
                                                             })}
                                                             value={jobNoValue?.unit || ''}
                                                            // {...register("unit", { required: true })} 
                                                            >
                                                            <option value="">Select a unit</option>
                                                            
                                                            {/* { dropDownData.map((option, index) => (
                                                                <option key={index} value={option.id}>
                                                                    {option.unit_name}
                                                                </option>
                                                            ))} */}
                                                            {dropDownData && Array.isArray(dropDownData) && dropDownData.length > 0 ? (
                                                                    dropDownData.map((option, index) => (
                                                                    <option key={index} value={option.id}>
                                                                        {option.unit_name}
                                                                    </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="" disabled>No units available</option>
                                                                )}
                                                            
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label" htmlFor="">Unit Price</label>
                                                    <div className="col-sm-9">
                                                        <input className="form-control" value={jobNoValue?.unit_price ||''} id="unit_price" onChange={clientStocksInputHander} name="unit_price" type="number" placeholder="" ref={register({})} />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="form-group row mt-2">
                                                <label className="col-sm-3 col-form-label" htmlFor="">Reordering Qty</label>
                                                <div className="col-sm-5">
                                                    <input className="form-control" id="reorder_qty" name="reorder_qty" type="number" onChange={clientStocksInputHander} placeholder="" value={jobNoValue?.reorder_qty ||''} ref={register({})} />
                                                </div>
                                            </div>
                                            <div className="form-group row mt-2">
                                                        <label className="col-sm-3 col-form-label" htmlFor="unit">
                                                            Product Source
                                                        </label>
                                                        <div className="col-sm-5">
                                                            <select
                                                            className="form-control"
                                                            id="product_source"
                                                            name="product_source" onChange={clientStocksInputHander}
                                                            ref={register({
                                                                required: "select one option"
                                                             })}
                                                            value={jobNoValue?.product_source ||''}
                                                            // {...register("product_source", { required: true })} 
                                                            >
                                                            <option value="">Select a Source</option>
                                                            <option value="1">Local</option>
                                                            <option value="2">Foreign</option>
                                                            <option value="3">Local/Foreign</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                            
                                           
                                           
                                            
                                        </div>
                                        <div className="col-md-4">
                                            
                                        </div>
                                    </div>
                                    <SubmitButton link="consumableList/index" offset="4" menuId={ menuId }/>
                                </form>
                                )
                            }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Add;