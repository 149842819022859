import React, { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  GET_PROPOSE_COLOR_LIST,
  JOB_ORDER_RSURL,
  userHasAccess,
} from "../../../api/userUrl";
import { userGetMethod, userDeleteMethod } from "../../../api/userAction";
import {
  AddButton,
  EditButton,
  ShowButton,
  DeleteButton,
  PerPageBox,
} from "../../common/GlobalButton";
import Pagination from "react-js-pagination";
// import { Button, Modal } from 'reactstrap';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Show from "./Show";
import "./listData.css";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useHistory } from 'react-router-dom';

export default function ListData(props) {
  const [jobOrderData, setJobOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [sortParam, setSortParam] = useState("");
  const [hasAccess, setHasAccess] = useState({});
  const [accLoad, setAccLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState();
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [ascDesc, setAscDesc] = useState(true);
  const [show, setShow] = useState(false);
  const [showColorList, setShowColorList] = useState(false);
  const [showId, setShowId] = useState(0);
  const [ProposeColorList, setProposeColorList] = useState([]);
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShowColorList(false);
  const handleShow = (id) => {
    setShow(true);
    setShowId(id);
  };

  var menuId = 0;
  if (props.location.state === undefined) {
    menuId = 0;
  } else {
    menuId = props.location.state.params.menuId;
  }
  // console.log(menuId);
  useEffect(() => {
    // ADD,EDIT,DELETE,SHOW ACCESS CHECK
    userGetMethod(`${userHasAccess}/${menuId}`).then((response) => {
      setHasAccess(response.data);
      setAccLoad(false);
    });
    // handlePageChange();
  }, []);

  useEffect(() => {
    perPageBoxChange();
  }, [perPage]);

  const handleSearchText = (e) => {
    setSearchText(e);
  };
  const searchHandler = (e) => {
    setIsLoading(true);
    userGetMethod(
      ascDesc === true
        ? `${JOB_ORDER_RSURL}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`
        : `${JOB_ORDER_RSURL}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`
    )
      .then((response) => {
        setCurrentPage(response.data.jobOrders.current_page);
        // setPerPage(response.data.jobOrders.per_page)
        setTotalData(response.data.jobOrders.total);
        setJobOrderData(response.data.jobOrders.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchHandler();
    }
  };

  const deleteHandler = (itemId, deleteLink) => {
    userDeleteMethod(deleteLink, itemId)
      .then((response) => {
        if (response.data.status == 1) {
          setIsLoading(true);
          let newData = jobOrderData.filter((data) => data.id != itemId);
          setJobOrderData(newData);
          setIsLoading(false);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error));
  };

  // console.log(jobOrderData);
  const perPageBoxChange = (e) => {
    setIsLoading(true);
    // TABLE DATA READY
    userGetMethod(
      ascDesc === true
        ? `${JOB_ORDER_RSURL}?perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`
        : `${JOB_ORDER_RSURL}?perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`
    )
      .then((response) => {
        setCurrentPage(response.data.jobOrders.current_page);
        // setPerPage(response.data.jobOrders.per_page)
        setTotalData(response.data.jobOrders.total);
        setJobOrderData(response.data.jobOrders.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
    // TABLE DATA READY
    userGetMethod(`${GET_PROPOSE_COLOR_LIST}`)
      .then((response) => {
        setProposeColorList(response.data.proposeColors);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handlePageChange = (pageNumber = 1) => {
    setIsLoading(true);
    // console.log(pageNumber)
    // TABLE DATA READY
    userGetMethod(
      ascDesc === true
        ? `${JOB_ORDER_RSURL}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`
        : `${JOB_ORDER_RSURL}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`
    )
      .then((response) => {
        setCurrentPage(response.data.jobOrders.current_page);
        // setPerPage(response.data.jobOrders.per_page)
        setTotalData(response.data.jobOrders.total);
        setJobOrderData(response.data.jobOrders.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    let ascUrl = "";
    if (ascDesc === true) {
      ascUrl = `${JOB_ORDER_RSURL}?perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`;
    } else {
      ascUrl = `${JOB_ORDER_RSURL}?perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`;
    }

    setIsLoading(true);
    // TABLE DATA READY
    userGetMethod(ascUrl)
      .then((response) => {
        setCurrentPage(response.data.jobOrders.current_page);
        // setPerPage(response.data.jobOrders.per_page)
        setTotalData(response.data.jobOrders.total);
        setJobOrderData(response.data.jobOrders.data);
        // console.log(jobOrderData);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [ascDesc]);

  const sortHandler = (params) => {
    setSortParam(params);
    setAscDesc((prevAscDesc) => !prevAscDesc);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-12">
                    <h5>Job Order List</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="input-group text-box searchBox">
                    <input
                      type="text"
                      className="form-control input-txt-bx col-md-8"
                      placeholder="Type to Search..."
                      onChange={(e) => handleSearchText(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={searchHandler}
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-6">
                  <OverlayTrigger
                    placement="top" // Position the tooltip at the top
                    overlay={<Tooltip id="tooltip-top">Propose color</Tooltip>}
                  >
                    <IoMdNotificationsOutline
                      size={30}
                      color="blue"
                      style={{
                        cursor: "pointer",
                        marginTop: "1rem",
                        textAlign: "right",
                      }}
                      onClick={() => setShowColorList(true)}
                    />
                  </OverlayTrigger>
                  <Badge bg="danger"><span style={{fontSize:'18px'}}>{ProposeColorList.length}</span></Badge>
                  <AddButton link="jobOrder/add" menuId={menuId} />
                  <PerPageBox
                    pageBoxChange={perPageBoxChange}
                    perPage={perPage}
                    setPerPage={setPerPage}
                  />
                </div>
              </div>

              <div className="card-body datatable-react">
                {isLoading ? (
                  <img
                    src={process.env.PUBLIC_URL + "/preloader.gif"}
                    alt="Data Loading"
                  />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-border-horizontal">
                      <thead>
                        <tr>
                          {/* <th scope="col" width="8%" > SL.</th> */}
                          <th
                            scope="col"
                            width="5%"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-sort"></i> SL.
                          </th>
                          <th
                            scope="col"
                            width="5%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(1)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i> Job No.
                          </th>
                          <th
                            scope="col"
                            width="15%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(2)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i> Job Name
                          </th>
                          <th
                            scope="col"
                            width="15%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(3)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i> Client
                          </th>
                          <th
                            scope="col"
                            width="10%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(4)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i> Marketing Person
                          </th>
                          <th
                            scope="col"
                            width="15%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(5)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i> Printer Name
                          </th>
                          <th
                            scope="col"
                            width="10%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(6)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i> Job Type
                          </th>
                          <th
                            scope="col"
                            width="8%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(6)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i>Surface
                          </th>
                          <th
                            scope="col"
                            width="10%"
                            style={{ cursor: "pointer" }}
                            onClick={() => sortHandler(1)}
                          >
                            {" "}
                            <i className="fa fa-sort"></i>Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobOrderData.length > 0 ? (
                          <>
                            {jobOrderData.map((item, index) => (
                              <tr key={index}>
                                <td scope="row">
                                  {index +
                                    1 +
                                    (currentPage == 1
                                      ? 0
                                      : currentPage * perPage - perPage)}
                                </td>
                                <td>{item.job_no}</td>
                                <td>{item.job_name}</td>
                                <td>{item.client_name}</td>
                                <td>{item.marketing_p_name}</td>
                                <td>{item.printer_name}</td>
                                <td>{item.job_type}</td>
                                <td>{item.job_type}</td>
                                <td className="">
                                  {accLoad === false ? (
                                    <>
                                      {hasAccess.edit === true &&
                                      item?.agreement_status != 1 ? (
                                        <EditButton
                                          link={`/jobOrder/edit/${item.id}`}
                                          menuId={menuId}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {hasAccess.show === true ? (
                                        <ShowButton
                                          handleShow={() => handleShow(item.id)}
                                          menuId={menuId}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {hasAccess.destroy === true &&
                                      item?.agreement_status != 1 ? (
                                        <DeleteButton
                                          deleteLink={JOB_ORDER_RSURL}
                                          deleteHandler={deleteHandler}
                                          menuId={menuId}
                                          dataId={item.id}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colSpan="9" className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalData}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal======== */}
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{ maxWidth: "none", width: "100%" }}>
            <Modal.Title>Job Order Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Show showId={showId}></Show>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal show={showColorList} onHide={handleClose2}>
          <Modal.Header closeButton style={{ maxWidth: "none", width: "100%" }}>
            <Modal.Title>Propose Color List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <table className="table table-border-horizontal">
                <thead>
                  <tr>
                    {/* <th scope="col" width="8%" > SL.</th> */}
                    <th scope="col" width="5%" style={{ cursor: "pointer" }}>
                      <i className="fa fa-sort"></i> SL.
                    </th>
                    <th
                      scope="col"
                    //   width="5%"
                      style={{ cursor: "pointer" }}
                    //   onClick={() => sortHandler(1)}
                    >
                      {" "}
                      <i className="fa fa-sort"></i> Job No.
                    </th>
                    <th
                      scope="col"
                    //   width="15%"
                      style={{ cursor: "pointer" }}
                    //   onClick={() => sortHandler(2)}
                    >
                      {" "}
                      <i className="fa fa-sort"></i> Job Name
                    </th>
                    <th
                      scope="col"
                    //   width="10%"
                      style={{ cursor: "pointer", textAlign:'center'}}
                    //   onClick={() => sortHandler(1)}
                    >
                      {" "}
                      <i className="fa fa-sort"></i>Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ProposeColorList.length > 0 ? (
                    <>
                      {ProposeColorList.map((item, index) => (
                        <tr key={index}>
                          <td scope="row">
                            {index +
                              1 +
                              (currentPage == 1
                                ? 0
                                : currentPage * perPage - perPage)}
                          </td>
                          <td>{item.job_order.job_no}</td>
                          <td>{item.job_order.job_name}</td>
                          
                          <td className="text-center">
                            {accLoad === false ? (
                              <>
                                {/* <Button className="btn btn-primary" onClick={()=> history.push(`/layoutColor/${item.id}`)}>Propose Color</Button> */}
                                  <EditButton
                                    link={`/confirm-layoutColor/${item.job_order.id}`}
                                    menuId={menuId}
                                  />
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
}
